import { render, staticRenderFns } from "./submitPrompt.vue?vue&type=template&id=5e60f10d&scoped=true&"
import script from "./submitPrompt.vue?vue&type=script&lang=js&"
export * from "./submitPrompt.vue?vue&type=script&lang=js&"
import style0 from "./submitPrompt.vue?vue&type=style&index=0&id=5e60f10d&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e60f10d",
  null
  
)

export default component.exports