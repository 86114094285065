<template>
  <!-- 退出答题提示 -->
  <van-dialog v-model="show"
              title=""
              :showConfirmButton="false">
    <van-icon name="cross"
              @click="close" />
    <div class="tp">
      <img src="@/assets/img/submitPrompt1.png"
           alt="" />
    </div>
    <p>
      {{ $t("submitPrompt.subTxt1")
      }}<span style="color: #f53838; margin: 0 10px">{{ noAnswer }}</span>
    </p>
    <p>
      {{ $t("submitPrompt.subTxt2")
      }}<span style="color: #2b8df0; margin: 0 10px">{{
        qsTotalNum - noAnswer
      }}</span>
    </p>
    <p class="clstips"
       v-if="noAnswer != 0"
       style="padding: 0 15px">
      {{ $t("submitPrompt.subTxt5")
      }}<span style="color: red">{{ $t("submitPrompt.subTxt6") }}</span>
    </p>
    <div class="footer"
         style="padding: 0 15px">
      <van-button type="info"
                  :disabled="noAnswer != 0 || isDisable"
                  @click="submit">{{ $t("submitPrompt.subTxt3") }}</van-button>
      <van-button type="default"
                  :disabled="isDisable"
                  @click="goOn">{{
        $t("submitPrompt.subTxt4")
      }}</van-button>
    </div>
  </van-dialog>
</template>

<script>
import { Toast } from "vant";
import { savePjDetail } from "@api/wxpx.js";
export default {
  props: {
    noAnswer: {
      type: Number,
      default: 0,
    },
    qsTotalNum: {
      type: Number,
      default: 0,
    },
    qsTiKu: {
      type: Array,
      default: [],
    },
    iflag: {
      type: Number,
      default: 0,
    },
    mautoid: {
      type: Number,
      default: 0,
    },
    paperid: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: "",
    },
  },
  data () {
    const userInfo = localStorage.userInfo
      ? JSON.parse(localStorage.userInfo)
      : {};
    return {
      userInfo,
      show: false,
      isDisable: false,
    };
  },
  methods: {
    close () {
      this.show = false;
    },
    submit () {
      console.log(this.qsTiKu, "qsTiKuqsTiKu");
      let submitData = [];
      this.qsTiKu.forEach((ele, idx) => {
        if (ele.opflag == 0)        {
          // ele.opt.forEach((val,key) =>{
          let obj = {};
          obj.autoid = ele.autoid;
          obj.opres = ele.answer;
          submitData.push(obj);
          // })
        } else if (ele.opflag == 1)        {
          console.log(ele, 'eleeleele')
          // ele.opt.forEach((val,key) =>{
          let obj = {};
          obj.autoid = ele.autoid;
          obj.opres = ele.answer.join(",");
          submitData.push(obj);
          // })
        } else if (ele.opflag == 2)        {
          // ele.opt.forEach((val,key) =>{
          let obj = {};
          obj.autoid = ele.autoid;
          obj.opres = ele.answer;
          submitData.push(obj);
          // })
        } else if (ele.opflag == 4)        {
          // ele.opt.forEach((val,key) =>{
          let obj = {};
          obj.autoid = ele.autoid;
          obj.opres = ele.answer;
          submitData.push(obj);
          // })
        }
      });
      let submitObj = {
        iflag: this.iflag,
        mautoid: this.mautoid,
        paperid: this.paperid,
        data: submitData,
        empid: this.userInfo.empid,
        empname: this.userInfo.empname,
      };
      savePjDetail(submitObj).then((res) => {
        console.log(res, "submitObjsubmitObj");
        if (res.iserror == 0)        {
          Toast(this.$t("module.submitScu"));
          this.isDisable = true;
          this.$router.push({
            path: "/kcpjtjPage",
            query: {
              isAnswer: this.qsTotalNum - this.noAnswer,
              noAnswer: this.noAnswer,
              iflag: this.iflag,
              mautoid: this.mautoid,
              paperid: this.paperid,
              title: this.title,
            },
          });
        }
      });
      // this.$router.push({path:'/submitPage',query:{isAnswer:this.qsTotalNum-this.noAnswer,noAnswer:this.noAnswer}})
    },
    goOn () {
      this.show = false;
    },
  },
};
</script>

<style lang='less' scoped>
.van-dialog {
  width: 652px;
  height: 860px;
  font-family: Source Han Sans SC;
  overflow: visible;
  .van-icon {
    width: 28px;
    height: 28px;
    position: absolute;
    right: 40px;
    top: 32px;
  }

  /deep/.van-dialog__content {
    position: relative;
    padding-top: 300px;
    text-align: center;
    .tp {
      position: absolute;
      top: -240px;
      left: 50%;
      transform: translateX(-50%);
      width: 342px;
      height: 608px;
      img {
        height: 100%;
        width: 100%;
      }
    }
    p {
      color: #333;
      font-size: 36px;
    }
    .footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      .van-button {
        width: 50%;
      }
      .van-button:first-child {
        margin-bottom: 24px;
      }
    }
  }

  .clstips {
    font-size: 28px !important;
  }
}
</style>
